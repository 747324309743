/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 21:15:10
 * @Description:实力瘦通用逻辑处理
 * @FilePath: /frontend/src/mixins/shilishou-rebate.js
 */
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import { Toast, Dialog } from 'vant';
import { eq } from 'lodash';
import { getDate, getStartedAt, getEndedAt } from '@/utils/common.js';
import { SHILISHOU_LEVEL_MAP, getRouteNameByLevel } from '@/constants/rebate';
import Rmb from '@/components/common/rmb/index.vue';
import { getUserInfoByDate, getRebateActivity, getRebateSystems } from '@/service/rebate/shilishouRebate';

const defaultVal = '--';

/**
 * 通过比较路由名称以及查询参数是否相同判断是不是同一个路由对象
 * @param {*} routeName
 * @param {*} query
 */
function isSameRoute(currentRoute, routeName, query) {
    const { name, query: currQuery } = currentRoute;
    if (name !== routeName) {
        return false;
    } else {
        if (eq(currQuery, query)) {
            return true;
        }
    }

    return false;
}

export default {
    components: { Rmb },

    data() {
        return {
            currDate: getDate(this.$route.query.startTime),
            rebateInfo: {},
            defaultVal,
            agentId: this.$route.query.agentId,
            userInfoObj: null,
            currSystemId: 1,
            currActivityId: 2,
        };
    },

    computed: {
        ...mapState({
            userId: state => state.user.user.id,
        }),
        //  计算userInfo的等级名称
        userInfo() {
            const { defaultVal, userInfoObj } = this;
            const { id = defaultVal, name = defaultVal, headImg, levelId = defaultVal, type } = userInfoObj || {};
            let levelName = SHILISHOU_LEVEL_MAP[levelId] || defaultVal;
            if (String(type) === '1' && String(levelId) === '3') {
                levelName = '股东';
            }
            if (String(type) === '0' && String(levelId) === '3') {
                levelName = '联创';
            }
            return {
                id,
                name,
                headImg,
                levelName,
                levelId,
            };
        },
        startTime() {
            return getStartedAt(this.currDate);
        },
        endTime() {
            return getEndedAt(this.currDate);
        },
    },

    watch: {
        currDate: {
            immediate: true,
            handler: async function currDateHandler(newVal, oldVal) {
                const { agentId } = this;
                const { startTime, endTime } = this;
                this.reset();
                // if (startTime && startTime.indexOf("2020-06") >= 0) {
                //   // 六月份的展示因为后端查询不正确，前端不去请求数据，提示六月份业绩不支持查看
                //   Toast("2020年06月业绩数据维护中～请切换其他月份查看～");
                //   return;
                // }

                const systemList = (await getRebateSystems()) || [];
                this.currSystemId = systemList[0] ? systemList[0].id : '';

                const activityList =
                    (await getRebateActivity({
                        systemId: this.currSystemId,
                    })) || [];
                this.currActivityId = activityList[0] ? activityList[0].id : '';

                //  请求当前月份用户的授权信息
                const userInfoObj =
                    (await getUserInfoByDate({
                        userId: this.agentId,
                        startTime,
                        endTime,
                        systemId: systemList[0].id,
                    })) || {};

                let routeName = this.$route.name;
                const levelName = SHILISHOU_LEVEL_MAP[userInfoObj.levelId] || '无授权';
                routeName = getRouteNameByLevel(userInfoObj.levelId);
                // if (this.isRebateHome) {
                //     //  如果是一级页面页面，则根据用户等级跳转到对应的业绩页面
                //     routeName = getRouteNameByLevel(userInfoObj.levelId);
                // } else {
                //     //  不是一级页面，判断用户等级是否发生变化，如果发生变化，提示用户是否跳转对应等级的业绩首页进行查看
                //     if (
                //         this.userInfoObj &&
                //         this.userInfoObj.levelId !== userInfoObj.levelId &&
                //         userInfoObj.levelId !== '-1'
                //     ) {
                //         try {
                //             await Dialog.confirm({
                //                 title: `是否跳转到当前等级对应的业绩首页`,
                //                 message: `选中的月份${startTime}的授权等级为${levelName},暂不支持业绩查看～`,
                //             });
                //             routeName = getRouteNameByLevel(userInfoObj.levelId);
                //         } catch (error) {
                //             //  点击了取消
                //         }
                //     }
                // }

                //  如果无授权或者无对应授权的页面提示并且提留在当前页面
                // if (!userInfoObj.levelId || userInfoObj.levelId === '-1' || !routeName) {
                //     //  提示没有授权
                //     Toast(`选中的月份${startTime}的授权等级为${levelName},暂不支持业绩查看～`);
                //     //  实际上这个设定会再次触发currDateHandler这个方法，导致重定向到重复的路由，所以页面会提示warning
                //     // this.currDate = oldVal;
                //     return;
                // }

                if (!isSameRoute(this.$route, routeName, { agentId, startTime, endTime })) {
                    //  利用路由记录变化
                    this.$router.push({
                        name: routeName,
                        query: {
                            agentId,
                            startTime,
                            endTime,
                            activityId: this.currActivityId,
                            systemId: this.currSystemId,
                        },
                    });
                }
                this.userInfoObj = userInfoObj;
                this.init();

                if (dayjs(newVal).isAfter(dayjs('2020-10-31 23:59:59'))) {
                    routeName = 'nov-new-rebate-home';
                    this.$router.push({
                        name: routeName,
                        query: {
                            agentId: this.userId,
                            startTime,
                            endTime,
                            activityId: this.currActivityId,
                            systemId: this.currSystemId,
                        },
                    });
                    // try {
                    //     await Dialog.confirm({
                    //         title: `是否跳转到11月份之前的业绩`,
                    //         message: `11月份业绩在开发中,暂不支持业绩查看～`,
                    //     });
                    //     routeName = getRouteNameByLevel(userInfoObj.levelId);
                    // } catch (error) {
                    //     //  点击了取消
                    // }
                    // this.$router.push({
                    //     name: routeName,
                    //     query: {
                    //         agentId: this.userId,
                    //         startTime: '2020-10-01 00:00:00',
                    //         endTime: '2020-10-31 23:59:59',
                    //     },
                    // });
                    // location.reload();
                }
            },
        },
    },

    methods: {
        init() {},

        reset() {
            this.rebateInfo = {};
        },

        //  点击折扣管理详情
        clickRebateBtn() {
            this.$router.push({
                name: 'shilishou-rebate-release-management',
                query: { ...this.$route.query },
            });
        },

        //  根据路由名称跳转到对应的页面
        onRebateDetail(routeName) {
            const { agentId, startTime, endTime, currActivityId, currSystemId } = this;
            this.$router.push({
                name: routeName,
                query: { agentId, startTime, endTime, activityId: currActivityId, systemId: currSystemId },
            });
        },
    },

    created() {},
};

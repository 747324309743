/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 14:05:28
 * @Description:实力瘦股东折扣首页
 * @FilePath: /frontend/src/pages/rebate/shilishou/share-holders/rebate-home/index.js
 */
import CompRebateLayoutOne from "@/components/rebate/comp-rebate-layout-one/index.vue";
import rebateMixin from "@/mixins/shilishou-rebate";
import { getShareHoldersRebateSum } from "@/service/rebate/shilishouRebate";

export default {
  name: "share-holders-rebate-home",
  mixins: [rebateMixin],
  components: {
    CompRebateLayoutOne,
  },
  data() {
    return {
      isRebateHome: true
    };
  },
  methods: {
    //  初始化页面
    init() {
      const { startTime, endTime, agentId } = this;
      getShareHoldersRebateSum({
        startTime,
        endTime,
        userId: agentId,
      }).then((data) => {
        this.rebateInfo = data;
      });
    },
  },
};

/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 17:47:19
 * @Description:实力瘦业绩相关接口
 * @FilePath: /frontend/src/service/rebate/shilishouRebate.js
 */
import { HttpV1 as HTTP } from '@/request';

/**
 * 获取股东首页折扣明细
 */
export async function getShareHoldersRebateSum({ userId, endTime, startTime }) {
    const data = await HTTP.get(`/agent/rebate/solder`, {
        userId,
        endTime,
        startTime,
    });
    return data;
}

/**
 * 获取股东首页团队销售折扣
 */
export async function getShareHoldersTeamPurch({ userId, endTime, startTime }) {
    const data = await HTTP.get(`/agent/rebate/solderTeamDiscount`, {
        userId,
        endTime,
        startTime,
    });
    return data;
}

/**
 * 获取联合创始人首页折扣明细
 */
export async function getCoFounderRebateSum({ userId, endTime, startTime }) {
    const data = await HTTP.get(`/agent/rebate/unite`, {
        userId,
        endTime,
        startTime,
    });
    return data;
}

/**
 * 获取联合创始人团队团购业绩
 */
export async function getCoFounderTeamPurch({ userId, endTime, startTime }) {
    const data = await HTTP.get(`/agent/rebate/uniteTeamDiscount`, {
        userId,
        endTime,
        startTime,
    });
    return data;
}

/**
 * 获取战略合伙人首页折扣明细
 */
export async function getStrategyRebateSum({ userId, endTime, startTime }) {
    const data = await HTTP.get(`/agent/rebate/strategy`, {
        userId,
        endTime,
        startTime,
    });
    return data;
}

/**
 * 获取联合创始人首页折扣明细
 */
export async function getUserInfoByDate({ userId, endTime, startTime }) {
    const data = await HTTP.get(`/agent/rebate/userInfo`, {
        userId,
        endTime,
        startTime,
    });
    return data;
}

export async function getRecommendBonus({ userId, endTime, startTime }) {
    const data = await HTTP.get(`/agent/rebate/recommend`, {
        userId,
        endTime,
        startTime,
    });
    return data;
}

/**
 * 获取订单明细
 * @param {*} param0
 */
export async function getTeamPurchDetail({ userId, endTime, startTime }) {
    const data = await HTTP.get(`/agent/rebate/teamDiscount/detail`, {
        userId,
        endTime,
        startTime,
    });
    return data;
}

/**
 * 获取团队达标业绩
 * @param {*} param0
 */
export async function getStandardBonus({ userId, endTime, startTime }) {
    const data = await HTTP.get(`/agent/rebate/solderTeamStandards`, {
        userId,
        endTime,
        startTime,
    });
    return data;
}

/**
 * 获取折扣奖励折扣管理列表
 * @param {*} param0
 */
export async function getReleaseBonus({ userId, endTime, startTime, levelId }) {
    const data = await HTTP.get(`/agent/rebate/discountAward`, {
        userId,
        endTime,
        startTime,
        levelId,
    });
    return data;
}

export async function getRebateActivity({ systemId }) {
    const data = await HTTP.get(`/agent/new-rebate/activity`, {
        systemId,
    });
    return data;
}

export async function getRebateSystems() {
    const data = await HTTP.get(`/agent/new-rebate/systems`);
    return data;
}
